// import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
/**
 * Refers to app/javascript/stylesheets/application.scss (or application.css)
 * Note that we don't need to preface this path with "app/javascript" due to the `source_path` config set in config/webpacker.yml. Magical!
 * The file extension can be left off due to the `extensions` config in config/webpacker.yml.
 */
import 'stylesheets/application';
import '@boxt/design-system/dist/styles.css';

import Rails from 'rails-ujs';

import { Init as Chat } from 'Features/Chat';
import { Init as ErrorPage } from 'Features/ErrorPage';
import { Init as HowBoxtWorks } from 'Features/HowBoxtWorks';
import Navigation from 'Features/Nav';
import Promotions from 'Features/Promotions';
import { Init as SalePromoBanner } from 'Features/SalePromoBanner';
import { Init as Trustpilot } from 'Features/Trustpilot';

import { addApplets } from './applets';

Rails.start();
const applets = [
  {
    init: SalePromoBanner,
    el: 'react-sale-promo-banner-desktop',
  },
  {
    init: SalePromoBanner,
    el: 'react-sale-promo-banner-mobile',
  },
  {
    init: Navigation,
    el: 'react-global-nav',
  },
  {
    init: Trustpilot,
    el: 'react-trustpilot-reviews',
  },
  {
    init: HowBoxtWorks,
    el: 'react-how-boxt-works',
  },
  {
    init: Promotions,
    el: 'react-promotions',
  },
  {
    init: ErrorPage,
    el: 'react-error-page',
  },
  {
    init: Chat,
    el: 'react-chat-widget',
  },
];

addApplets(applets);
