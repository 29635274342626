import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ children }) => <div className="r-modal-footer">{children}</div>;

Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default Footer;
