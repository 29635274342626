const STATUS = {
  invalid: 'invalid',
  inactive: 'inactive',
};

const PROMO_TYPE = {
  fixed: 'BoxtEngine::Shopping::PromoCodes::FixedAmount',
  freePackage: 'BoxtEngine::Shopping::PromoCodes::FreePackage',
};

export { PROMO_TYPE, STATUS };
