import React from 'react';
import PropTypes from 'prop-types';

import H4 from 'Patterns/copy/H4';
import Paragraph from 'Patterns/copy/Paragraph';
import Icon from 'Patterns/ui/Icon';

import content from './Toast.data';

const Toast = ({ promoCode, closeToast }) => {
  const { name, type } = promoCode;
  const toastContent = content[type](promoCode);

  return (
    <div className="promo-toast__notification">
      <button className="r-modal-header__close" onClick={closeToast} type="button">
        <span className="visually-hidden">Close</span>
      </button>
      <H4 align="center" bottom={3}>
        <span role="img" aria-label="Tada" className="promo-toast__emoji">
          🎉
        </span>{' '}
        {toastContent.title}
      </H4>
      <Paragraph align="center" size="small" bottom={5} theme="slate">
        {toastContent.body}
      </Paragraph>
      <div className="promo-toast__code-container">
        <Icon name="discount" classname="promo-toast__icon" />
        <Paragraph size="small" theme="jade">
          <strong className="promo-toast__code">{name}</strong> applied
        </Paragraph>
      </div>
    </div>
  );
};

Toast.propTypes = {
  closeToast: PropTypes.func,
  promoCode: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
};

Toast.defaultProps = {
  closeToast: undefined,
};

export default Toast;
