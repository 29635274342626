import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Copy from '../components';

const H5 = forwardRef(({ className, ...rest }, ref) => (
  <Copy ref={ref} className={classnames('h5', className)} {...rest} />
));

H5.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
};

H5.defaultProps = {
  as: 'h5',
  className: undefined,
};

H5.displayName = 'H5';

export default H5;
