import classnames from 'classnames';
import PropTypes from 'prop-types';

import Column from './column';
import Grid from './container';
import Row from './row';

Grid.Col = Column;
Grid.Row = Row;

// 16px x 16px visual grid background
Grid.Debug16 = ({ className, ...props }) => (
  <Grid className={classnames(className, 'bx--grid--debug--16')} {...props} />
);

Grid.Debug16.propTypes = {
  className: PropTypes.string,
};
Grid.Debug16.defaultProps = {
  className: '',
};

// 8px x 8px visual grid background
Grid.Debug8 = ({ className, ...props }) => <Grid className={classnames(className, 'bx--grid--debug--8')} {...props} />;

Grid.Debug8.propTypes = {
  className: PropTypes.string,
};
Grid.Debug8.defaultProps = {
  className: '',
};

export default Grid;
