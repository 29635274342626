import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import H3 from 'Patterns/copy/H3';
import H4 from 'Patterns/copy/H4';

import { sizes } from '../../constants';

const Header = ({ headline, children, onClick, classname, hideCloseBtn, size }) => {
  const Headline = size === sizes.SMALL ? H4 : H3;
  return (
    <div className="r-modal-header">
      {!hideCloseBtn && (
        <button className="r-modal-header__close" onClick={onClick} type="button">
          <span className="visually-hidden">Close</span>
        </button>
      )}

      {headline && (
        <Headline
          align="center"
          weight="normal"
          bottom={6}
          className={classNames('r-modal-header__headline', classname)}
        >
          {headline}
        </Headline>
      )}
      {children}
    </div>
  );
};

Header.propTypes = {
  classname: PropTypes.string,
  children: PropTypes.element,
  headline: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  hideCloseBtn: PropTypes.bool.isRequired,
  size: PropTypes.oneOf([sizes.SMALL, sizes.MEDIUM, sizes.LARGE, sizes.XLARGE]).isRequired,
};

Header.defaultProps = {
  classname: undefined,
  headline: undefined,
  children: null,
};

export default Header;
