import React, { useEffect, useState } from 'react';

import currencyFormatter from 'Helpers/currency';
import useAnalytics from 'Hooks/useAnalytics';
import Button from 'Patterns/controls/Button';
import H5 from 'Patterns/copy/H5';
import Paragraph from 'Patterns/copy/Paragraph';
import ImgixProxy from 'Patterns/ui/ImgixProxy';

const PlaybackWidget = () => {
  const allowedPaths = ['/', '/boilers'];
  const widgetData = localStorage.getItem('playbackWidgetData');
  const widgetObject = JSON.parse(widgetData);
  const WITH_WIDGET_CLASSNAME = 'with-playback-widget';
  const [isOpen, setIsOpen] = useState(true);
  const shouldShowWidget = allowedPaths.includes(window.location.pathname) && isOpen;
  const segmentAnalytics = useAnalytics();
  const { itemMinPrice, itemMaxPrice, itemsLength, screeningId } = widgetObject;

  useEffect(() => {
    if (segmentAnalytics && shouldShowWidget) {
      segmentAnalytics.track('Playback Widget Viewed', {
        numberOfBoilers: itemsLength,
        lowestToHighestPrice: `${itemMinPrice} - ${itemMaxPrice}`,
      });
    }
  }, [shouldShowWidget, segmentAnalytics, itemMinPrice, itemMaxPrice, itemsLength]);

  useEffect(() => {
    const onPageLoad = () => {
      if (!document.body.classList.contains(WITH_WIDGET_CLASSNAME) && shouldShowWidget) {
        document.body.classList.add(WITH_WIDGET_CLASSNAME);
      }
    };
    if (document.readyState === 'complete') {
      return onPageLoad();
    }
    window.addEventListener('load', onPageLoad);
    return () => window.removeEventListener('load', onPageLoad);
  }, [shouldShowWidget]);

  const handleClick = () => {
    setIsOpen(false);
    document.body.classList.remove(WITH_WIDGET_CLASSNAME);
  };

  if (!shouldShowWidget) return null;

  return (
    <div className="playback-widget">
      <div className="wrapper">
        <H5 className="large-only">Finish your quotes?</H5>
        <Button afix="none" size="tiny" className="close-icon-button" onClick={handleClick} icon="cross" />
        <br className="large-only" />
        <div className="info-container">
          <ImgixProxy
            className="boiler-image"
            sizes="110px, 110px"
            alt="Boiler image"
            src={require('Images/products/w-bosch-greenstar-3d.png')}
          />
          <Paragraph>{itemsLength} boilers matched</Paragraph>
          <small>Quotes from</small>
          <br />
          <span>
            <strong>
              {currencyFormatter(itemMinPrice)} - {currencyFormatter(itemMaxPrice)}
            </strong>
          </span>
          <Button
            className="small"
            component="link"
            href={`/find-a-boiler/${screeningId}/bundles`}
            theme="jade"
            icon="right-arrow"
            size="small"
            afix="suffix"
          >
            Continue
          </Button>
        </div>
        <br />
        <Button
          className="large"
          isFullWidth
          component="link"
          href={`/find-a-boiler/${screeningId}/bundles`}
          theme="jade"
          icon="right-arrow"
          afix="suffix"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default PlaybackWidget;
