import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { toast, ToastContainer } from 'react-toastify';

import Button from 'Patterns/controls/Button';
import Paragraph from 'Patterns/copy/Paragraph';
import Modal, { sizes } from 'Patterns/utils/Modal';

import Toast from './components/Toast';
import { STATUS } from './constants';

const Promotions = ({ el, codeError, promoCode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (codeError === STATUS.inactive || codeError === STATUS.invalid) {
      setIsModalOpen(true);
    }
  }, [codeError]);

  useEffect(() => {
    if (!codeError && Object.keys(promoCode).length > 0) {
      toast(<Toast promoCode={promoCode} />, { containerId: 'promotion' });
    }
  }, [codeError, promoCode]);

  const removePromoQueryParam = () => {
    const queryParams = queryString.parse(window.location.search, { sort: false });
    delete queryParams.promo;

    return queryString.stringifyUrl({ url: window.location.origin, query: queryParams });
  };

  const closeModal = () => {
    setIsModalOpen(false);

    const url = removePromoQueryParam();
    window.history.replaceState('', '', url);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        headline="Something isn’t quite right"
        onRequestClose={closeModal}
        el={el}
        hideCloseBtn
        size={sizes.SMALL}
        shouldCloseOnOverlayClick={false}
      >
        <Paragraph align="center" bottom={{ sm: 5, md: 6, lg: 6 }}>
          It looks like either the promotion has ended or the URL you have used is invalid.
        </Paragraph>
        <Paragraph align="center" bottom={{ sm: 5, md: 6, lg: 6 }}>
          Don’t worry, we often have installation day discounts so you can still save on your order.
        </Paragraph>
        <Button onClick={closeModal} size="medium" theme="jade" isFullWidth>
          Continue
        </Button>
      </Modal>
      {promoCode && (
        <ToastContainer
          className="promo-toast"
          closeButton={false}
          autoClose={false}
          closeOnClick={false}
          enableMultiContainer
          limit={1}
          containerId="promotion"
          pauseOnFocusLoss={false}
          hideProgressBar
          pauseOnHover={false}
          draggable={false}
          toastClassName="promo-toast__toast"
          bodyClassName="promo-toast__body"
        />
      )}
    </>
  );
};

Promotions.propTypes = {
  codeError: PropTypes.string,
  el: PropTypes.shape({}).isRequired,
  promoCode: PropTypes.shape({
    active: PropTypes.bool,
    code: PropTypes.string,
    freePackage: PropTypes.shape({}),
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.number,
  }),
};

Promotions.defaultProps = {
  codeError: '',
  promoCode: {},
};

export default Promotions;
