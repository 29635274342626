import React from 'react';
import ReactDOM from 'react-dom';

import Promotions from './Promotions';

const Init = (el) => {
  const { error } = el.dataset;
  const promoCode = JSON.parse(el.dataset.json || null);

  ReactDOM.render(<Promotions codeError={error} promoCode={promoCode} el={el} />, el);
};

export default Init;
