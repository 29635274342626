import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Image = ({ alt, classname, src, srcSet }) => (
  <img
    alt={alt}
    src={require(`Images/${src}`)}
    className={classNames(classname)}
    srcSet={
      srcSet.length > 0 ? srcSet.map((item) => `${require(`Images/${item.src}`)} ${item.value}`).join(', ') : undefined
    }
  />
);

Image.propTypes = {
  alt: PropTypes.string,
  classname: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  srcSet: PropTypes.arrayOf(PropTypes.shape({ src: PropTypes.string, value: PropTypes.string })),
};

Image.defaultProps = {
  alt: '',
  classname: undefined,
  srcSet: [],
};

export default Image;
