import React from 'react';
import ReactDOM from 'react-dom';

import ErrorPage from './ErrorPage';

const Init = (el) => {
  const { body, ctaCopy, ctaUrl, headline } = el.dataset;
  ReactDOM.render(<ErrorPage body={body} ctaCopy={ctaCopy} ctaUrl={ctaUrl} headline={headline} />, el);
};

export { Init };
export default ErrorPage;
