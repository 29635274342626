import defaultValues from './default';

const acAnswer = {
  icon: 'hiw-answer-ac',
};

export default {
  items: defaultValues.items.map((item, i) => {
    switch (i) {
      case 0:
        return { ...item, ...acAnswer };
      default:
        return item;
    }
  }),
};
