export default [
  '01_buildings_detached.png',
  '02_buildings_semidetached.png',
  '03_buildings_terraced.png',
  '04_buildings_bungalow_1story.png',
  '05_buildings_bungalow_2story.png',
  '06_buildings_flat.png',
  '07_TRV_v1.0.png',
  '08_TRV_v1.1.png',
  '09_nonTRV_v1.0.png',
  '10_nonTRV_v1.1.png',
  '11_nonTRV_v2.0.png',
  '12_nonTRV_v2.1.png',
  '13_RoofFlueExit.png',
  '14_WallFlueExit.png',
  '15_UnderCarport.png',
  '16_NotUnderCarport.png',
  '18_FlueLessThan1mFromWall.png',
  '18_FlueLessThan2mFromWall_No.png',
  '18_FlueLessThan2mFromWall_Yes.png',
  '19_WallExitRound.png',
  '20_WallExitSquare.png',
  '21_RoofTypeFlat.png',
  '22_RoofTypePitched.png',
  '23_BottomThird.png',
  '24_TopThird.png',
  '25_FlueMoreThan2mFromGrounbd.png',
  '25_FlueMoreThan2mFromGround_No.png',
  '25_FlueMoreThan2mFromGround_Yes.png',
  '30cm-or-less-less.png',
  '30cm-or-less-more.png',
  'character--launch.svg',
  'clouds--launch.svg',
  'dont-know.png',
  'heat-burst.svg',
  'launch--90-seconds.svg',
  'launch.svg',
  '10_nonTRV_v1.1.png',
].reduce(
  (acc, string) => ({
    ...acc,
    [`screening/${string}`]: require(`./${string}`),
  }),
  {},
);
