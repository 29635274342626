import { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash-es';

export const setTestAnonymousId = () => {
  if (isEmpty(window?.boxtEnv)) return;
  if (window.boxtEnv !== 'production') {
    //  Each of these new users costs us £££ so to get round this we need to make segment think that
    // all our Dev & QA users are all the same person
    window.analytics.setAnonymousId('868b6bb2-49f2-4389-8a84-26f0fac8ef79');
  }
};
const useAnalytics = () => {
  const segment = useRef(null);

  useEffect(() => {
    if (window !== undefined && segment.current === null) {
      segment.current = window.analytics;
      setTestAnonymousId();
    }
  }, [segment]);

  return segment.current;
};

export default useAnalytics;
