import { useState } from 'react';

import validateIntercomUser from 'Features/Chat/dataAccess';
import loadIntercomScript from 'Hooks/useChat/intercomScript';

const { intercomAppId, intercomApiBase } = window;
const useChat = (token = null) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const load = async (...rest) => {
    return new Promise((resolve) => {
      if (!window?.Intercom?.booted) {
        loadIntercomScript({
          appId: intercomAppId,
          onLoad: async () => {
            const intercomSettings = {
              api_base: intercomApiBase,
              app_id: intercomAppId,
              horizontal_padding: 20,
              vertical_padding: 20,
            };

            if (token) {
              try {
                const {
                  data: { email, id },
                } = await validateIntercomUser(token);

                intercomSettings.user_id = id;
                intercomSettings.email = email;
              } catch {
                /* empty */
              }

              window?.Intercom('boot', intercomSettings);

              const url = new URL(window.location);
              const params = new URLSearchParams(url.search);
              params.delete('token');
              const query = params.toString() || '';
              window.history.replaceState(
                {},
                document.title,
                `${window.location.pathname}${query ? `?${query}` : query}${window.location.hash}`,
              );
              if (rest) {
                window.Intercom(...rest);
              }
              window?.Intercom('showNewMessage');
              setIsLoaded(true);
              return resolve(null);
            }

            window?.Intercom('boot', intercomSettings);
            setIsLoaded(true);
            return resolve(rest ? window.Intercom(...rest) : '');
          },
        });
      }

      setIsLoaded(true);

      return resolve(rest ? window.Intercom(...rest) : '');
    });
  };

  const update = async (data) => load('update', data);

  const getVisitorId = async () => load('getVisitorId');

  const onShow = async (cb) => load('onShow', cb);

  const openChat = async () => load('showNewMessage');

  const minimize = async () => load('update', { hide_default_launcher: false });

  const hide = async () => load('update', { hide_default_launcher: true });

  return {
    isLoaded,
    update,
    getVisitorId,
    onShow,
    openChat,
    hide,
    load,
    minimize,
  };
};

export default useChat;
