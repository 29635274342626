import { evChargerScreeningUrl } from 'Helpers/boxtConfig';

import defaultValues from './default';

const ev = () => ({
  items: defaultValues.items,
  ctaCopy: 'Get your charger now',
  ctaTarget: evChargerScreeningUrl,
});

export default ev;
