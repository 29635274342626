import React from 'react';
import ReactDOM from 'react-dom';

import { air_con, ev, home_cover } from './collections';
import HowBoxtWorks from './HowBoxtWorks';

const data = {
  ev,
  air_con,
  home_cover,
};

const Init = (el) => {
  const { tag } = el.dataset;
  const props = { ...data[tag], product: tag };
  ReactDOM.render(<HowBoxtWorks {...props} />, el);
};

export { air_con, ev, home_cover, Init };
export default HowBoxtWorks;
