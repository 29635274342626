import React from 'react';
import PropTypes from 'prop-types';

import createKey from 'Helpers/createKey';
import Button from 'Patterns/controls/Button';
import H1 from 'Patterns/copy/H1';
import Paragraph from 'Patterns/copy/Paragraph';
import Spacing from 'Patterns/layout/Spacing';

import { defaultValues } from './collections';
import Card from './components/Card';

const HowBoxtWorks = ({ ctaButton, ctaCopy, ctaTarget, headline, items, product, subheadline }) => (
  <section className="how-boxt-works l-container">
    <H1 as="h2" align="center">
      {headline}
    </H1>
    <Paragraph size="large" top={{ sm: 7, md: 8, lg: 9 }} align="center" className="how-boxt-works__subheadline">
      {subheadline}
    </Paragraph>
    <Spacing mt={{ sm: 4, md: 5, lg: 6 }} mb={{ sm: 4, md: 5, lg: 6 }}>
      <div className="how-boxt-works__items">
        {items.map((item) => (
          <Card key={createKey(item.title)} title={item.title} icon={item.icon} copy={item.copy} product={product} />
        ))}
      </div>
    </Spacing>
    {ctaButton && ctaTarget && (
      <div className="how-boxt-works-cta">
        <Button component={ctaButton} href={ctaTarget} isFullWidth skin="secondary" targetId={ctaTarget} theme="jade">
          {ctaCopy}
        </Button>
      </div>
    )}
  </section>
);

HowBoxtWorks.propTypes = {
  ctaButton: PropTypes.string,
  ctaCopy: PropTypes.string,
  ctaTarget: PropTypes.string,
  headline: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      copy: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
  product: PropTypes.string,
  subheadline: PropTypes.string,
};

HowBoxtWorks.defaultProps = {
  ctaButton: defaultValues.ctaButton,
  ctaCopy: defaultValues.ctaCopy,
  ctaTarget: defaultValues.ctaTarget,
  headline: defaultValues.headline,
  items: defaultValues.items,
  product: null,
  subheadline: defaultValues.subheadline,
};

export default HowBoxtWorks;
