module.exports = {
  airConScreeningUrl: '/find-ac',
  boilerScreeningUrl: '/find-a-boiler',
  customerServicesEmail: 'customerservice@boxt.co.uk',
  evChargerScreeningUrl: '/find-an-ev-charger',
  faqsUrl: '/faqs',
  financeUrl: '/finance',
  homeCoverTermsUrl: '/boilers/home-cover-terms-and-conditions',
  howBoxtWorksUrl: '/how-boxt-works',
  servicingEmail: 'servicing@boxt.co.uk',
  tel: '0800 193 7777',
  whoWeAreUrl: '/who-we-are',
};
