import React from 'react';
import PropTypes from 'prop-types';

const Body = ({ children }) => (
  <div className="r-modal__body">
    <div className="r-modal__content">{children}</div>
  </div>
);

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  ]).isRequired,
};

export default Body;
