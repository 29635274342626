import { currencyFormatter } from 'Helpers';

import { PROMO_TYPE } from '../../constants';

export default {
  [PROMO_TYPE.fixed]: ({ value }) => ({
    title: `Take ${currencyFormatter(value)} off`,
    body: `You’ll receive ${currencyFormatter(value)} off your order at check out`,
  }),
  [PROMO_TYPE.freePackage]: ({ free_package }) => {
    const freePackageName = free_package.display_name || free_package.name;

    return {
      title: 'Gift added',
      body: `You’ll receive a free ${freePackageName} at checkout`,
    };
  },
};
