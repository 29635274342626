import { useEffect } from 'react';
import { lowerCase } from 'lodash-es';

import { useChat } from 'Hooks/useChat';

const Chat = ({ source, openChatInitial, token }) => {
  const { onShow, update, openChat } = useChat(token);

  useEffect(() => {
    (async () => {
      await onShow(() =>
        update({
          source: `Email: ${lowerCase(source)}`,
          locationUrl: window.location.href,
        }),
      );
      if (openChatInitial) {
        await openChat();
      }
    })();
  });

  return null;
};

export default Chat;
