import React from 'react';

import Chat from './Chat';

const Init = (el) => {
  const { source, openChat, token } = el.dataset;

  ReactDOM.render(<Chat source={source} token={token} openChatInitial={openChat === 'true'} />, el);
};

export { Init };
export default Chat;
