import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import dangerouslySetInnerHTML from 'Helpers/dangerouslySetInnerHTML';
import H4 from 'Patterns/copy/H4';
import Paragraph from 'Patterns/copy/Paragraph';
import Icon from 'Patterns/ui/Icon';

const Card = ({ copy, icon, product, title }) => (
  <div
    className={classNames('how-boxt-works-item', {
      [`how-boxt-works-item--${product}`]: product,
    })}
  >
    <Icon name={icon} classname="how-boxt-works-item__icon" />
    <H4 align="center" bottom={5}>
      {title}
    </H4>
    <Paragraph align="center">{dangerouslySetInnerHTML(copy)}</Paragraph>
  </div>
);

Card.propTypes = {
  copy: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  product: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Card.defaultProps = {
  product: undefined,
};

export default Card;
