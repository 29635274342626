import React, { Component } from 'react';
import classNames from 'classnames';
import noScroll from 'no-scroll';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import Spacing from 'Patterns/layout/Spacing';

import { Header } from './components';
import { sizes } from './constants';

class Modal extends Component {
  componentDidUpdate() {
    const { isOpen } = this.props;
    if (!isOpen) {
      noScroll.off();
    }
  }

  onOpen = () => {
    noScroll.on();
  };

  onClose = () => {
    const { onRequestClose } = this.props;
    noScroll.off();
    onRequestClose();
  };

  getSpacing = () => {
    const { size } = this.props;
    switch (size) {
      case sizes.SMALL:
        return { mt: 4, mb: 2, mh: 2 };
      default:
        return { mh: 3, mv: { sm: 3, md: 4 } };
    }
  };

  render() {
    const {
      el,
      classname,
      isOpen,
      children,
      modalHeader,
      headline,
      headlineClassname,
      size,
      hideCloseBtn,
      shouldCloseOnOverlayClick,
    } = this.props;
    return (
      <ReactModal
        onAfterOpen={this.onOpen}
        appElement={el}
        isOpen={isOpen}
        onRequestClose={this.onClose}
        className={classNames('r-modal', size, classname)}
        overlayClassName="r-modal__overlay"
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <Spacing {...this.getSpacing()}>
          <Header
            hideCloseBtn={hideCloseBtn}
            onClick={() => this.onClose()}
            headline={headline}
            classname={headlineClassname}
            size={size}
          >
            {modalHeader}
          </Header>

          {children}
        </Spacing>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  ]).isRequired,
  classname: PropTypes.string,
  el: PropTypes.shape({}).isRequired,
  headline: PropTypes.string,
  headlineClassname: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  modalHeader: PropTypes.element,
  onRequestClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf([sizes.SMALL, sizes.MEDIUM, sizes.LARGE, sizes.XLARGE]),
  hideCloseBtn: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

Modal.defaultProps = {
  classname: undefined,
  headline: undefined,
  headlineClassname: undefined,
  modalHeader: null,
  size: sizes.MEDIUM,
  hideCloseBtn: false,
  shouldCloseOnOverlayClick: true,
};

export default Modal;
