import { howBoxtWorksUrl } from 'Helpers/boxtConfig';

export default {
  headline: 'How BOXT works',
  subheadline:
    "With just a few simple questions, we'll suggest the right boiler, air conditioner or EV charger for you",
  items: [
    {
      icon: 'hiw-answer-ev',
      title: 'You answer',
      copy: 'A few questions about your car and home, and send us a few photos',
    },
    {
      icon: 'hiw-choose',
      title: 'You choose',
      copy: "A package that's tailored for you and an install date",
    },
    {
      icon: 'hiw-installer',
      title: 'We install',
      copy: 'Our professional engineers will carry out the installation hassle and mess free',
    },
  ],
  ctaButton: 'link',
  ctaCopy: 'Learn more',
  ctaTarget: howBoxtWorksUrl,
};
