export default {
  homeowner: {
    headline: 'How BOXT cover plans work',
    subheadline: 'Getting covered with BOXT takes less time than making a cup of tea',
    items: [
      {
        icon: 'hiw-choose',
        title: 'Choose a plan',
        copy: 'Our plans are designed to be stress-free with absolutely no call-out charges.',
      },
      {
        icon: 'hiw-cover-pay',
        title: 'Set up payment',
        copy: 'Set up a Direct debit and you’ll be covered straight away. You’ll receive your policy document by email.',
      },
      {
        icon: 'hiw-installer',
        title: 'You’re covered',
        copy: 'We’ll be in touch to arrange your annual service & with Protect+ you can easily contact us to fix your heating problems.',
      },
    ],
    ctaButton: 'scroll-to',
    ctaCopy: 'Read our FAQs',
    ctaTarget: 'home-cover-faqs',
  },
  landlord: {
    headline: 'How BOXT cover plans work',
    subheadline: 'Getting covered with BOXT takes less time than making a cup of tea',
    items: [
      {
        icon: 'hiw-choose',
        title: 'Choose a plan',
        copy: 'Our plans are designed to be stress-free with absolutely no call-out charges. If you have multiple properties, you’ll need one for each.',
      },
      {
        icon: 'hiw-cover-pay',
        title: 'Set up payment',
        copy: 'Set up a Direct debit and you’ll be covered straight away. You’ll receive your policy document by email.',
      },
      {
        icon: 'hiw-installer',
        title: 'You’re covered',
        copy: 'We’ll be in touch to arrange your annual service with a Landlord Gas Safety Certificate. With Protect+ you can easily contact us to fix your heating problems.',
      },
    ],
    ctaButton: 'scroll-to',
    ctaCopy: 'Read our FAQs',
    ctaTarget: 'home-cover-faqs',
  },
};
