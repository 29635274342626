import React from 'react';
import PropTypes from 'prop-types';

import Button from 'Patterns/controls/Button';
import H2 from 'Patterns/copy/H2';
import Paragraph from 'Patterns/copy/Paragraph';
import Grid from 'Patterns/layout/Grid';
import Image from 'Patterns/ui/Image';

const ErrorPage = ({ headline, body, ctaCopy, ctaUrl }) => {
  const image = require('Images/error-page/oops.svg');
  return (
    <div className="r-error-page">
      <Grid>
        <Grid.Row className="r-error-page__row">
          <Grid.Col sm={4} md={5} lg={9} xlg={8}>
            <Image className="r-error-page__img" src={image} alt="error-page-oops" width={180} />
            <H2 top={{ sm: 7, md: 9 }}>{headline}</H2>
            <Paragraph size="large" top={{ sm: 5, lg: 6 }} bottom={{ sm: 7, md: 9 }}>
              {body}
            </Paragraph>
            <Button component="link" href={ctaUrl} theme="jade">
              {ctaCopy}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
};

ErrorPage.propTypes = {
  headline: PropTypes.string,
  body: PropTypes.string.isRequired,
  ctaCopy: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

ErrorPage.defaultProps = {
  headline: 'Oops, something went wrong',
};

export default ErrorPage;
