import React from 'react';
import PropTypes from 'prop-types';

import H1 from 'Patterns/copy/H1';
import Spacing from 'Patterns/layout/Spacing';
import TrustpilotWidget from 'Patterns/others/TrustpilotWidget';

const TrustPilot = ({ title }) => {
  return (
    <Spacing mv={6} mh={{ sm: 0, md: 3 }}>
      <section className="r-trustpilot-review">
        <div className="l-container">
          <H1 as="h2" bottom={7} align="center">
            {title}
          </H1>
        </div>
        <div className="l-container r-trustpilot-review__container">
          <TrustpilotWidget theme="light" />
        </div>
      </section>
    </Spacing>
  );
};

TrustPilot.propTypes = {
  title: PropTypes.string,
};

TrustPilot.defaultProps = {
  title: 'What our customers say',
};

export default TrustPilot;
