import React from 'react';
import ReactDOM from 'react-dom';

import SalePromoBanner from './SalePromoBanner';

const Init = (el) => {
  const hideOnDesktop = JSON.parse(el.dataset.hideOnDesktop || false);
  const hideOnTablet = JSON.parse(el.dataset.hideOnTablet || true);

  ReactDOM.render(
    <SalePromoBanner hideOnDesktop={hideOnDesktop} hideOnTablet={hideOnTablet}>
      <strong>BLACK FRIDAY WEEK</strong> - Get an <strong>EXTRA £100 OFF</strong> any boiler on our booking calendar -
      this week only!
    </SalePromoBanner>,
    el,
  );
};

export { Init };
export default SalePromoBanner;
