import React, { useState } from 'react';
import { FlagshipProvider, LogLevel } from '@flagship.io/react-sdk';

import onVisitorExposedHelper from 'Helpers/onVisitorExposedHelper';
import useSegmentId from 'Hooks/useSegmentId';

const NavProvider = ({ children, flagshipEnvId, flagshipApiKey }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const { segmentId } = useSegmentId();

  return (
    <FlagshipProvider
      envId={flagshipEnvId}
      apiKey={flagshipApiKey}
      logLevel={LogLevel.ERROR}
      visitorData={{ id: segmentId, isAuthenticated: Boolean(segmentId) }}
      onUpdate={() => setHasLoaded(true)}
      enableClientCache
      onVisitorExposed={onVisitorExposedHelper}
    >
      {React.cloneElement(children, {
        hasLoaded,
      })}
    </FlagshipProvider>
  );
};

export default NavProvider;
