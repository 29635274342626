import React from 'react';
import ReactDOM from 'react-dom';

import TrustPilot from './TrustPilot';

const Init = (el) => {
  const { title } = el.dataset;
  ReactDOM.render(<TrustPilot title={title} />, el);
};

export { Init };
export default TrustPilot;
