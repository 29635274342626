import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Paragraph from 'Patterns/copy/Paragraph';

import data from './SalePromoBanner.data';
import themes from './themes.constants';

const SalePromoBanner = ({ icon, theme, children, hideOnDesktop, hideOnTablet }) => {
  const classes = classNames('sale-promo-banner', {
    'sale-promo-banner--dark': theme === themes.DARK,
    'sale-promo-banner--light': theme === themes.LIGHT,
    'sale-promo-banner--desktop-hidden': hideOnDesktop,
    'sale-promo-banner--tablet-hidden': hideOnTablet,
  });

  return (
    <div className={classes}>
      <div className="sale-promo-banner__container">
        <img className="sale-promo-banner__image" src={require(`Images/sale-promo-banner/${icon}.svg`)} alt="" />
        <Paragraph className="sale-promo-banner__copy" theme="dark">
          {children}
        </Paragraph>
      </div>
    </div>
  );
};

SalePromoBanner.propTypes = {
  children: PropTypes.node.isRequired,
  hideOnDesktop: PropTypes.bool,
  hideOnTablet: PropTypes.bool,
  icon: PropTypes.string,
  theme: PropTypes.string,
};

SalePromoBanner.defaultProps = {
  hideOnDesktop: false,
  hideOnTablet: true,
  icon: data.icon,
  theme: themes.DARK,
};

export default SalePromoBanner;
