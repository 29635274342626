import { Column } from 'carbon-components-react';
import { isInteger, isObject } from 'lodash-es';

const breakpoints = ['sm', 'md', 'lg']; // where one exists, all 3 are required

const validateIsInteger = (value, propName) =>
  isInteger(value)
    ? null // valid
    : new Error(`${propName} in Grid.Col is not a number`);

const validateIsGreaterThan1 = (value, propName) =>
  value > 1
    ? null // valid
    : new Error(`${propName} in Grid.Col is less than 1. Must have a span of at least 1`);

const validateHasAllRequiredRelatedProps = (componentProps, propName) => {
  if (!breakpoints.includes(propName)) return null;

  return breakpoints.every((bp) => componentProps[bp])
    ? null
    : new Error(
        `${propName} in Grid.Col does not have values for sm, md and lg. You cannot specify one without all three.`,
      );
};

const validateIsWithColumnBounds = (propName, value, max) =>
  value <= max
    ? null
    : new Error(
        `${propName}=${value} in Grid.Col has value greater than the number of columns at that breakpoint (${max})`,
      );

const validateIsWithinColumnBoundsForBreakpoints = (value, propName) => {
  switch (propName) {
    case 'sm':
      return validateIsWithColumnBounds(propName, value, 4);
    case 'md':
      return validateIsWithColumnBounds(propName, value, 8);
    default:
      return validateIsWithColumnBounds(propName, value, 16);
  }
};

const responsiveProps = (componentProps, propName) => {
  const value = !isObject(componentProps[propName]) ? componentProps[propName] : componentProps[propName].span;

  if (value) {
    return (
      validateIsInteger(value, propName) ||
      validateIsGreaterThan1(value, propName) ||
      validateHasAllRequiredRelatedProps(componentProps, propName) ||
      validateIsWithinColumnBoundsForBreakpoints(value, propName)
    );
  }

  return null;
};

// avoiding overwriting existing prop types / defaults
const GridCol = (props) => <Column {...props} />;

GridCol.propTypes = {
  sm: responsiveProps,
  md: responsiveProps,
  lg: responsiveProps,
  xlg: responsiveProps,
  max: responsiveProps,
};
GridCol.defaultProps = {
  sm: null,
  md: null,
  lg: null,
  xlg: null,
  max: null,
};

export default GridCol;
