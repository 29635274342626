import React from 'react';
import ReactDOM from 'react-dom';

import Nav from './Nav';
import NavProvider from './NavProvider';

const Init = (el) => {
  const { activeSubnavId, flagshipEnvId, flagshipApiKey } = el.dataset;

  ReactDOM.render(
    <NavProvider flagshipEnvId={flagshipEnvId} flagshipApiKey={flagshipApiKey}>
      <Nav activeSubnav={activeSubnavId} />
    </NavProvider>,
    el,
  );
};

export default Init;
